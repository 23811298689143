const cardFrame = document.querySelectorAll('.card-frame.card-circle');
const imageEl = "picture";
const circleSize = 20

cardFrame.forEach(el => {
    const image = el.querySelector('.card-img-bottom');

    el.addEventListener("mouseenter", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.set(image, {
            clipPath: `circle(0% at ${positionX}px ${positionY}px)`
        })
    });

    el.addEventListener("mousemove", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.to(image, {
            duration: 0.5,
            clipPath: `circle(${circleSize}% at ${positionX}px ${positionY}px)`
        })
    });

    el.addEventListener("mouseleave", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.to(image, {
            duration: 0.5,
            delay: 0.2,
            clipPath: `circle(0% at ${positionX}px ${positionY}px)`,
        })
    });
});