function hpVideoControlsInit() {
    const video = document.querySelector('#video-home video');

    if (video) {
        const videoControl = document.querySelector('#video-home .video-control');
        const playPause = videoControl.querySelector('.video_play-pause');
        const soundOnOff = videoControl.querySelector('.video_sound-on-off');
        const fullScreen = videoControl.querySelector('.video_full-screen');

        playPause.addEventListener("click", () => {
            if (video.paused == false) {
                playPause.querySelector('.mynd-pause').classList.replace('mynd-pause', 'mynd-play');
                video.pause();
            } else {
                playPause.querySelector('.mynd-play').classList.replace('mynd-play', 'mynd-pause');
                video.play();
            }
        });

        soundOnOff.addEventListener("click", () => {
            if (video.muted == false) {
                soundOnOff.querySelector('.mynd-sound-on').classList.replace('mynd-sound-on', 'mynd-sound-off');
                video.muted = true;
            } else {
                soundOnOff.querySelector('.mynd-sound-off').classList.replace('mynd-sound-off', 'mynd-sound-on');
                video.muted = false;
            }
        });


        fullScreen.addEventListener("click", () => {
            openFullscreen(video);
        });

        function openFullscreen(myVideo) {
            var elem = myVideo;

            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE/Edge */
                elem.msRequestFullscreen();
            }
        }
    }
}