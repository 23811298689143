function maskImageInit() {
    let masksContainer = document.querySelectorAll('.mask-image--container');

    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
    let tablet = window.matchMedia('(min-width: 768px)');

    if (masksContainer[0]) {
        masksContainer.forEach(maskContainer => {
            if (mobile.matches && maskContainer.classList.contains('mobile-disabled')) {
                mask.classList.remove('mask-image')
            } else {
                let mask = maskContainer.querySelector('.mask-image')
                let image = mask.querySelector('img');

                // let maskSection = mask.closest('*[data-mask-parent]');

                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: maskContainer,
                        // toggleActions: "restart none none reset",
                        markers: false,
                        // start: "top-=25% center"
                        start: "top center"
                    }
                });

                tl.set(mask, { autoAlpha: 1 });

                if (maskContainer.classList.contains('to-top')) {
                    tl.from(mask, 1, {
                        yPercent: -100,
                        ease: Power2.out
                    });
                    tl.from(image, 1, {
                        yPercent: 120,
                        scale: 1.3,
                        delay: -1,
                        ease: Power2.out
                    });
                } else if (maskContainer.classList.contains('to-bottom')) {
                    tl.from(mask, 1, {
                        yPercent: 100,
                        ease: Power2.out
                    });
                    tl.from(image, 1, {
                        yPercent: -120,
                        scale: 1.3,
                        delay: -1,
                        ease: Power2.out
                    });
                } else {
                    tl.from(mask, 1, {
                        xPercent: -100,
                        ease: Power2.out
                    });
                    tl.from(image, 1, {
                        xPercent: 120,
                        scale: 1.3,
                        delay: -1,
                        ease: Power2.out
                    });
                }
            }
        })
    }
}