function vantagesSliderInit() {
    const swiperCase = new Swiper('.vantages-wrapper', {
        spaceBetween: 0,
        slidesPerView: 2.1,
        freeMode: true,

        breakpoints: {
            768: {
                slidesPerView: 4,
            },
        },

        navigation: {
            prevEl: '.slider-navigation-prev',
            nextEl: '.slider-navigation-next',
            disabledClass: 'disabled'
        },
    });

    return swiperCase;
}