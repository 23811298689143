function execOnce(fn, context) {
    var result;
    return function () {
        if (fn) {
            result = fn.apply(context || this, arguments);
            fn = null;
        }
        return result;
    };
}


const accordionBrand = document.querySelector('.accordion.accordion--collapse');
if (accordionBrand) {
    accordionBrand.addEventListener('hidden.bs.collapse', event => {
        ScrollTrigger.refresh();
    })

    accordionBrand.addEventListener('show.bs.collapse', event => {
        ScrollTrigger.refresh();
    })
}


function sectionBrandInit() {
    if (accordionBrand) {
        const sectionBrandPhoneHtml = document.querySelector('.section-brand').innerHTML;
        const accordionHeader = accordionBrand.querySelectorAll('.accordion-header');
        const accordionCollapse = accordionBrand.querySelectorAll('.accordion-collapse');
        const accordionItem = accordionBrand.querySelectorAll('.accordion-item');

        function sectionBrandTablet() {
            const nameContainer = document.createElement("div");
            nameContainer.classList.add('name-container');

            accordionHeader.forEach(el => {
                nameContainer.appendChild(el);
            });

            const collapseContainer = document.createElement("div");
            collapseContainer.classList.add('collapse-container');

            accordionBrand.appendChild(nameContainer);
            accordionBrand.classList.add('accordion-grid');

            accordionBrand.append(collapseContainer);
            accordionCollapse.forEach(el => {
                collapseContainer.appendChild(el);
            });

            accordionItem.forEach(el => {
                el.remove();
            });
        }

        function sectionBrandPhone() {
            sectionBrand.innerHTML = sectionBrandPhoneHtml;
        }

        return {
            sectionBrandTablet: sectionBrandTablet,
            sectionBrandPhone: sectionBrandPhone,
        }

    }
}

let sectionBrand = sectionBrandInit();

function accordionEmptyStateInit() {
    const accordionEmptyState = document.querySelector('.accordion.accordion--collapse');

    if (accordionEmptyState.classList.contains("accordion-grid")) {
        const accordionItem = accordionEmptyState.querySelectorAll('.accordion-button');

        accordionItem.forEach(el => {
            el.addEventListener('click', (e) => {
                let collapsed = e.target.classList.contains(".collapsed");

                accordionItem.forEach(button => {
                    button.disabled = false;
                });

                if (!collapsed) {
                    e.target.disabled = true
                }
            })
        });
    }
}