const swiperClient1 = new Swiper('.client-slider-1', {
    spaceBetween: 56,
    slidesPerView: 4,
    loop: true,
    noSwiping: false,
    allowTouchMove: false,
    speed: 2000,
    slidesPerView: "auto",

    autoplay: {
        delay: 0,
    },
});

const swiperClient2 = new Swiper('.client-slider-2', {
    spaceBetween: 56,
    slidesPerView: 3,
    loop: true,
    noSwiping: false,
    allowTouchMove: false,
    speed: 2000,
    slidesPerView: "auto",

    autoplay: {
        delay: 0,
        reverseDirection: true
    },
});