const cardBlend = document.querySelectorAll('.card-frame.card--blend');
// const imageEl = "picture";
// const circleSize = 20

cardBlend.forEach(el => {
    const circleBlend = el.querySelectorAll('.circle-blend');

    el.addEventListener("mouseenter", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.set(circleBlend, {
            yPercent: -50,
            xPercent: -50,
            x: positionX,
            y: positionY,

            opacity: 0,
        })
    });

    el.addEventListener("mousemove", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.to(circleBlend, {
            duration: 0.5,

            yPercent: -50,
            xPercent: -50,
            x: positionX,
            y: positionY,

            opacity: 1,
        })
    });

    el.addEventListener("mouseleave", e => {
        let elPositionX = el.getBoundingClientRect().x;
        let elPositionY = el.getBoundingClientRect().y;

        let positionX = e.clientX - elPositionX;
        let positionY = e.clientY - elPositionY;

        gsap.to(circleBlend, {
            duration: 0.5,
            delay: 0.2,

            yPercent: -50,
            xPercent: -50,
            x: positionX,
            y: positionY,

            opacity: 0,
        })
    });
});